import i18n from '@/plugins/LoadLang'
// === 建议配置formItem 放到computed， 不然无法响应 vue-i18n;

/**
 * 目前支持的匹配:
 * required         必填
 * positiveInteger  大于0的整数
 * words            请输入大写字母
 * lowerWords       请输入小写字母
 * maxNum10         小于10的正整数（1-9）
 * baseInput        匹配 字母数字和特殊字符-组成的文本
 * inputCode        匹配 字母数字和特殊字符-组成的文本，且文本长度在1~50之间
 * inputName        匹配 字母数字和特殊字符-组成的文本，且文本长度在1~50之间
 * any              匹配任何内容, 当使用动态rules时, 非必填内容需要默认使用any
 *
 * ✨动态长度匹配:
 * inputLen{N}       匹配N个字符以内
 * wordsLen{N}       匹配N个字符以内的大写字母(A-Z)
 * baseInput{N}      匹配N个字符以内的字母数数字和特殊字符-组成的文本
 *
 * 例:
 * inputLen10       匹配10个字符以内
 * inputLen33       匹配33个字符以内
 *
 * ✨限定长度匹配:
 * inputLen{N}~{M}    匹配N~M区间的字符数
 * wordsLen{N}~{M}    匹配N~M区间的字符数, 大写字母(A-Z)
 * baseInput{N}~{M}    匹配N~M区间的字符数, 字母数字和特殊字符-组成的文本
 *
 * 例:
 * inputLen10~20      匹配长度在10~20以内的字符
 * baseInput10~20      匹配10~20区间的字符数, 字母数字和特殊字符-组成的文本
 *
 */

const baseFormValidate = {
  required() {
    return {
      required: true,
      message: i18n.t('libsSz.key35')
    }
  },
  requiredCheckBox() {
    return {
      required: true,
      message: i18n.t('请选择必填项')
    }
  },
  positiveNonnegativeInteger() {
    return {
      pattern: /^(0|[1-9]\d*)$/,
      // message: i18n.t('lang.gles.common.enterCheckFormatMsg3')
      message: i18n.t('请输入大于或等于0的正整数')
    }
  },
  positiveInteger() {
    return {
      pattern: /^[1-9]\d*$/,
      message: i18n.t('lang.gles.common.enterCheckFormatMsg3')
    }
  },
  positiveNum() {
    return {
      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,6}[.]{0,1}[0-9]{0,2}$/,
      // message: i18n.t('lang.gles.common.enterCheckFormatMsg14')
      message: i18n.t('请输入不超过8位且大于0数字')
    }
  },
  words() {
    return {
      pattern: /^[A-Z]{0,}$/,
      message: i18n.t('lang.gles.common.enterCheckFormatMsg5')
    }
  },
  lowerWords() {
    return {
      pattern: /^[a-z]{0,}$/,
      message: i18n.t('lang.gles.common.enterCheckFormatMsg4')
    }
  },
  maxNum10() {
    return {
      pattern: /^([1-9]|10)$/,
      message: i18n.t('lang.gles.common.enterCheckFormatMsg2', [10])
    }
  },
  maxNum100() {
    return {
      // required: true,
      pattern: /^(([1-9][0-9]?)|100)$/,
      // message: i18n.t('lang.gles.common.enterCheckFormatMsg2', [10])
      // message: i18n.t('请输入1～100的整数', [10])
      message: '请输入1～100的整数'
    }
  },
  inputCode() {
    return {
      pattern: /^[0-9a-zA-Z\-]{1,50}$/,
      // message: i18n.t('lang.gles.common.enterCheckFormatMsg0')
      message: i18n.t('50个字符内，支持字母、数字、’‘-’组合')
    }
  },
  baseInput() {
    return {
      pattern: /^[0-9a-zA-Z\-]$/,
      message: i18n.t('lang.gles.common.enterCheckFormatMsg1')
    }
  },
  inputName() {
    // 50个字符，支持中文、字母、数字、’‘-’组合
    return {
      pattern: /^[\u4E00-\u9FA50-9a-zA-Z\-]{1,50}$/,
      // message: i18n.t('lang.gles.common.enterCheckFormatMsg0')
      message: i18n.t('50个字符内，支持中文、字母、数字、’‘-’组合')
    }
  },
  any() {
    // 匹配任何字符和任何长度
    return {
      pattern: /.{0,}/,
      message: ''
    }
  },
  min() {
    return {
      pattern: /^(1|[1-9]\d*)$/,
      message: '输入的值不可小于1',
      trigger: ['change', 'blur']
    }
  },
  max() {
    return {
      pattern: /^[1-9][0-9]{0,6}$/,
      message: '在库时长超过最大限制9999999分钟',
      trigger: ['change', 'blur']
    }
  },
  inputUrl() {
    return {
      pattern: /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$/,
      message: '请输入正确的url',
      trigger: ['change', 'blur']
    }
  }
}

// ===== 高阶
// 需要使用正则动态返回的rule, 优先级高于ruleMap中的内容
// 从数组的第一位开始做正则的匹配, 如果多个正则都匹配成功, 只取第一个
const ruleGenerateMap = [
  {
    exp: /^inputNumberLen((?<min>\d+)~)?(?<max>\d+)$/,
    output(key, { groups }) {
      const { min, max } = groups
      const pattern = new RegExp(`^.{${min || 0},${max}}$`)
      const message = [
        i18n.t('lang.gles.common.enterCheckFormatMsg6', [max]),
        i18n.t('lang.gles.common.enterCheckFormatMsg7', [min, max])
      ][Number(!!min)]
      return { pattern, message }
    }
  },
  {
    exp: /^inputLen((?<min>\d+)~)?(?<max>\d+)$/,
    output(key, { groups }) {
      const { min = 0, max } = groups
      const message = [
        i18n.t('lang.gles.common.enterCheckFormatMsg6', [max]),
        i18n.t('lang.gles.common.enterCheckFormatMsg7', [min, max])
      ][Number(!!min)]
      return { min: Number(min), max: Number(max), message }
    }
  },

  {
    exp: /^wordsLen((?<min>\d+)~)?(?<max>\d+)$/,
    output(key, { groups }) {
      const { min, max } = groups
      const pattern = new RegExp(`^[A-Z]{${min || 0},${max}}$`)
      const message = [
        i18n.t('lang.gles.common.enterCheckFormatMsg8', [max]),
        i18n.t('lang.gles.common.enterCheckFormatMsg9', [min, max])
      ][Number(!!min)]
      return { pattern, message }
    }
  },
  {
    exp: /^baseInput((?<min>\d+)~)?(?<max>\d+)$/,
    output(key, { groups }) {
      const { min, max } = groups
      const pattern = new RegExp(`^[0-9a-zA-Z\-]{${min || 0},${max}}$`)
      const message = [
        i18n.t('lang.gles.common.enterCheckFormatMsg10', [max]),
        i18n.t('lang.gles.common.enterCheckFormatMsg11', [min, max])
      ][Number(!!min)]
      return { pattern, message }
    }
  },
  {
    exp: /^positiveIntegerLen((?<min>\d+)~)?(?<max>\d+)$/,
    output(key, { groups }) {
      const { min = 1, max } = groups
      const pattern = new RegExp(`^[1-9](\\d{${min - 1 || 0},${max - 1 || 1}})?$`)
      const message = [
        // i18n.t('lang.gles.common.enterCheckFormatMsg15', [max]),
        // i18n.t('lang.gles.common.enterCheckFormatMsg16', [min, max])
        i18n.t(`请输入长度在${max}以内的数字`, [max]),
        i18n.t(`请输入长度在${min}至${max}以内的数字`, [min, max])
      ][Number(!!min)]
      return { pattern, message }
    }
  }
]

export default function(rules) {
  let rulesList = []

  if (typeof rules === 'string') {
    rulesList.push(rules)
  } else {
    rulesList = rules
  }

  return rulesList.map(ruleTypeStr => {
    const baseRule = baseFormValidate[ruleTypeStr]
    if (baseRule) return baseRule()

    const len = ruleGenerateMap.length
    for (let i = 0; i < len; i += 1) {
      const ruleItem = ruleGenerateMap[i]
      const ruleExpResult = ruleItem.exp.exec(ruleTypeStr)
      if (ruleExpResult) {
        return ruleItem.output(ruleTypeStr, ruleExpResult)
      }
    }
    console.error('{formValidate.js} 未找到可用的规则！')
    return {}
  })
}
